// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { BIN_ERRORS } from '@/tools/histogram/histogram.constants';
import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { CapsulePropertyOutputV1, ScalarPropertyV1, sqConditionsApi } from '@/sdk';
import { validateNumber } from '@/utilities/utilities';
import {
  convertFrequencyToHertz,
  convertFrequencyToPeriod,
  convertPeriodToFrequency,
  isFrequency,
  momentMeasurementStrings,
} from '@/datetime/dateTime.utilities';
import i18next from 'i18next';
import { INTERNAL_CAPSULE_PROPERTY_PREFIX } from '@/trendData/trendData.constants';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';

export function fetchConditionProperties(
  conditionId: string,
): Promise<{ capsuleProperties: CapsulePropertyOutputV1[]; conditionProperties: ScalarPropertyV1[] }> {
  return sqConditionsApi
    .getCondition({ id: conditionId })
    .then(({ data: { capsuleProperties, additionalProperties } }) => ({
      conditionProperties: additionalProperties,
      capsuleProperties: _.chain(capsuleProperties)
        .flatten()
        .reject((property) => _.startsWith(property.name, INTERNAL_CAPSULE_PROPERTY_PREFIX))
        .uniqBy('name')
        .sortBy('name')
        .value(),
    }))
    .finally(() => ({ conditionProperties: [], capsuleProperties: [] }));
}

/**
 * Fetches the available capsule properties for the specified condition. Requests will fail silently.
 */
export function fetchCapsuleProperties(conditionId: string): Promise<CapsulePropertyOutputV1[]> {
  return fetchConditionProperties(conditionId).then(({ capsuleProperties }) => capsuleProperties);
}

/**
 * Validates the bin configuration for bins by y-value. Returns one of BIN_ERRORS or '' if the param is valid.
 *
 * @param {Object} param - param defining a bin configuration
 * @param {Number} [payload.yValueBinMin] -  minimum y-value to be used for creating bins by y-value. Only
 *   applies to AGGREGATION_MODES.Y_VALUE
 * @param {Number} [payload.yValueBinMax] -  maximum y-value to be used for creating bins by y-value. Only
 *   applies to AGGREGATION_MODES.Y_VALUE
 * @param {Number} [payload.binSize] - size of the bin. Only applies to AGGREGATION_MODES.Y_VALUE
 * @param {Number} [payload.numberOfBins] - number of bins. Only applies to AGGREGATION_MODES.Y_VALUE
 * @returns {String} on of BIN_ERRORS if the param is invalid, or an empty String if the param is valid
 */
export function validateBinConfig(param) {
  const min = _.toNumber(param.yValueBinMin);
  const max = _.toNumber(param.yValueBinMax);
  const binSize = _.toNumber(param.binSize);
  const numberOfBins = _.toNumber(param.numberOfBins);

  if ((!_.isNil(param.numberOfBins) && !_.isFinite(numberOfBins)) || numberOfBins < 1) {
    return BIN_ERRORS.INT_REQUIRED;
  }

  if (!_.isFinite(min)) {
    return BIN_ERRORS.MIN_NOT_A_NUMBER;
  } else if (!_.isFinite(max)) {
    return BIN_ERRORS.MAX_NOT_A_NUMBER;
  } else if (min > max) {
    return BIN_ERRORS.MIN_EXCEEDS_MAX;
  } else if (binSize <= 0) {
    return BIN_ERRORS.INVALID_BIN_SIZE;
  }

  if (
    validateNumber(param.yValueBinMin) &&
    validateNumber(param.yValueBinMax) &&
    (validateNumber(param.numberOfBins) || validateNumber(param.binSize))
  ) {
    return '';
  }
}

/**
 * Checks for the correct ratio of filter value to period value
 * @param filter {Object} - The high or low pass filter to be tested
 * @param period {Object} - The period to be used for testing
 * @param ratio {Number} - The ratio required for this filter
 * @param requireSameType {boolean} - False if the filter and period can be opposite types (one period, one frequency)
 * @returns {boolean} - Whether the ratio requirements have been met
 */
export function checkCutOffRateRatio(
  filter: Partial<FrontendDuration>,
  period: Partial<FrontendDuration>,
  ratio,
  requireSameType = true,
) {
  const filterIsFrequency = isFrequency(filter.units);
  const periodIsFrequency = isFrequency(period.units);

  if (
    _.isNil(filter.value) ||
    _.isNil(filter.units) ||
    _.isNil(period.value) ||
    _.isNil(period.units) ||
    (requireSameType && filterIsFrequency !== periodIsFrequency)
  ) {
    // Data is not complete, return true to avoid showing warning messages before incorrect data is entered
    return true;
  }

  filter = filterIsFrequency ? convertFrequencyToPeriod(filter as FrontendDuration) : filter;
  period = periodIsFrequency ? convertFrequencyToPeriod(period as FrontendDuration) : period;
  const filterDuration = moment.duration(filter.value, momentMeasurementStrings(filter.units)).asMilliseconds();
  const periodDuration = moment.duration(period.value, momentMeasurementStrings(period.units)).asMilliseconds();

  return filterDuration / periodDuration >= ratio;
}

/**
 * Checks for the correct ratio of sampling period to window size (taps)
 * @param period - The period to be used for testing
 * @param window - The window size to be used for testing
 * @param ratio - The ratio required for this filter
 * @param ratioIsMin - False to check that the values are less than or equal to the ratio (true checks >=)
 * @returns True if the ratio requirements have been met
 */
export function checkWindowSizeRatio(
  period: ValueWithUnitsItem & { valid?: boolean },
  window: ValueWithUnitsItem & { valid?: boolean },
  ratio: number,
  ratioIsMin = true,
): boolean {
  if (
    _.isNil(window.value) ||
    _.isNil(window.units) ||
    _.isNil(period.value) ||
    _.isNil(period.units) ||
    period.value === 0
  ) {
    return true;
  }

  if (isFrequency(period.units)) {
    period = convertFrequencyToPeriod(period);
  }

  const windowDuration = moment.duration(window.value, momentMeasurementStrings(window.units)).asMilliseconds();
  const periodDuration = moment.duration(period.value, momentMeasurementStrings(period.units)).asMilliseconds();

  return ratioIsMin ? windowDuration / periodDuration >= ratio : windowDuration / periodDuration <= ratio;
}

/**
 * Calculates the error limit in units that match the cutoff
 * @param cutoff {Object} - The cutoff (used to know if we should return period or frequency units)
 * @param rate {Object} - The sampling rate to calculate the error limit from
 * @param ratio {Number} - The ratio required between the cutoff and sampling rate
 * @returns {string} - The value and units of the limit (i.e. '2 minute(s)')
 */
export function getCutoffRateErrorLimit(cutoff, rate, ratio) {
  const cutoffIsFrequency = isFrequency(cutoff.units);
  const rateIsFrequency = isFrequency(rate.units);

  if (cutoffIsFrequency) {
    const rateAsFrequency = rateIsFrequency ? rate : convertFrequencyToHertz(convertPeriodToFrequency(rate));
    return `${rateAsFrequency.value / ratio} ${rateAsFrequency.units}`;
  } else {
    const rateAsPeriod = rateIsFrequency ? convertFrequencyToPeriod(rate) : rate;
    const unit = _.find(DURATION_TIME_UNITS, (unit) => unit.unit[0] === rateAsPeriod.units);
    return `${rateAsPeriod.value * ratio} ${unit ? i18next.t(unit.translationKey) : rateAsPeriod.units}`;
  }
}
