// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDatasources } from '@/administration/datasources/datasources.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import i18next from 'i18next';
import {
  AgentStatusOutputV1,
  ConnectionOutputV1,
  ConnectionStatusOutputV1,
  ConnectorOutputV1,
  DatasourcesStatusOutputV1,
  DatasourceSummaryStatusOutputV1,
  ScalarPropertyV1,
  sqAgentsApi,
  sqDatasourcesApi,
  sqItemsApi,
  sqRequestsApi,
} from '@/sdk';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { logError } from '@/utilities/logger';
import { errorToast, infoToast, successToast } from '@/utilities/toast.utilities';
import { subscribe as subscribeToSocket } from '@/utilities/socket.utilities';
import { SyncStatusEnum } from '@/sdk/model/ConnectionStatusOutputV1';

let unsubscribeFn: () => void = _.noop;

export function subscribe() {
  unsubscribeFn();
  unsubscribeFn = subscribeToSocket({
    channelId: [SeeqNames.Channels.DatasourcesStatus],
    onMessage: ({ datasourcesStatus }) => {
      setDatasources(datasourcesStatus);
    },
  });
}

export function unsubscribe() {
  unsubscribeFn();
  unsubscribeFn = _.noop;
}

/**
 * Fetch datasources immediately and log an error if the datasources could not be fetched.
 */
export function fetchDatasourcesImmediately() {
  return sqAgentsApi
    .getDatasourcesStatus()
    .then(({ data: datasourcesStatus }) => datasourcesStatus)
    .catch((ex) => {
      logError(ex);
      return Promise.reject();
    });
}

/**
 * Returns the number of DISCONNECTED agents
 */
export function countDisconnectedAgents(agents: AgentStatusOutputV1[]) {
  if (_.isNil(agents)) {
    return 0;
  }
  return _.countBy(agents, (agent) => agent.status === SeeqNames.Connectors.Connections.Status.Disconnected).true || 0;
}

/**
 * Filters the list of datasources by the filter parameters provided and returns them in the severity order
 * (disconnected first, happy last)
 */
export function filterAndSortDatasources(
  datasources: DatasourceSummaryStatusOutputV1[],
  filterParams: FilterParameters,
) {
  if (_.isNil(datasources)) {
    return null;
  }

  const containsIgnoreCase = (str1, str2) => _.toString(str1).toLowerCase().includes(_.toString(str2).toLowerCase());

  const hasValue = (fieldValue) => {
    return !_.isEmpty(fieldValue);
  };

  let filteredDatasources = datasources;

  if (hasValue(filterParams.name)) {
    filteredDatasources = _.filter(filteredDatasources, (ds) => containsIgnoreCase(ds.name, filterParams.name));
  }

  if (hasValue(filterParams.datasourceClass)) {
    filteredDatasources = _.filter(filteredDatasources, (ds) =>
      containsIgnoreCase(ds.datasourceClass, filterParams.datasourceClass),
    );
  }

  if (hasValue(filterParams.datasourceId)) {
    filteredDatasources = _.filter(filteredDatasources, (ds) =>
      containsIgnoreCase(ds.datasourceId, filterParams.datasourceId),
    );
  }

  if (hasValue(filterParams.agentName)) {
    filteredDatasources = _.filter(
      filteredDatasources,
      (ds) => _.countBy(ds.connections, (conn) => containsIgnoreCase(conn.agentName, filterParams.agentName)).true > 0,
    );
  }

  if (hasValue(filterParams.status)) {
    filteredDatasources = _.filter(
      filteredDatasources,
      (ds) => _.countBy(ds.connections, (conn) => conn.status === filterParams.status).true > 0,
    );
  }

  // sorting
  const errorDatasources = _.sortBy(
    _.filter(filteredDatasources, (ds) => isError(ds)),
    'name',
  );
  filteredDatasources = _.difference(filteredDatasources, errorDatasources);
  const indexingDatasources = _.sortBy(
    _.filter(filteredDatasources, (ds) => isIndexing(ds)),
    'name',
  );
  filteredDatasources = _.difference(filteredDatasources, indexingDatasources);
  const warningDatasources = _.sortBy(
    _.filter(filteredDatasources, (ds) => isWarning(ds)),
    'name',
  );
  filteredDatasources = _.difference(filteredDatasources, warningDatasources);
  const happyDatasources = _.sortBy(
    _.filter(filteredDatasources, (ds) => isHappy(ds)),
    'name',
  );
  filteredDatasources = _.difference(filteredDatasources, happyDatasources);
  const notConnectable = _.sortBy(
    _.filter(filteredDatasources, (ds) => isNotConnectable(ds)),
    'name',
  );
  filteredDatasources = _.sortBy(_.difference(filteredDatasources, notConnectable), 'name');

  return _.concat(
    errorDatasources,
    indexingDatasources,
    warningDatasources,
    happyDatasources,
    notConnectable,
    filteredDatasources,
  );
}

export function getDatasourceStatus(datasource: DatasourceSummaryStatusOutputV1) {
  if (isError(datasource)) {
    return DatasourceStatus.Error;
  } else if (isIndexing(datasource)) {
    return DatasourceStatus.Indexing;
  } else if (isWarning(datasource)) {
    return DatasourceStatus.Warning;
  } else if (isHappy(datasource)) {
    return DatasourceStatus.Happy;
  } else if (isNotConnectable(datasource)) {
    return DatasourceStatus.NotConnectable;
  } else {
    return DatasourceStatus.Unknown;
  }
}

const isError = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount === 0 && ds.totalConnectionsCount > 0;
};

export function isIndexing(ds: DatasourceSummaryStatusOutputV1) {
  return _.includes(
    [SyncStatusEnum.ARCHIVINGDELETEDITEMS, SyncStatusEnum.INPROGRESS, SyncStatusEnum.INITIALIZING],
    ds.syncStatus,
  );
}

export function latestOf(moments: moment.Moment[]): moment.Moment {
  let latest = _.isEmpty(moments) ? moment(0) : moments[0];
  moments.forEach((thisMoment) => {
    latest = latest.isBefore(thisMoment) ? thisMoment : latest;
  });
  return latest;
}

export function isIndexingProgressing(referenceTime: moment.Moment, indexingNoProgressLimit: moment.Duration): boolean {
  return moment().isBefore((referenceTime ?? moment(0)).clone().add(indexingNoProgressLimit));
}

const isWarning = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount > 0 && ds.connectionsConnectedCount < ds.totalConnectionsCount;
};

const isHappy = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount === ds.totalConnectionsCount && ds.totalConnectionsCount > 0;
};

const isNotConnectable = (ds: DatasourceSummaryStatusOutputV1) => {
  return ds.connectionsConnectedCount === 0 && ds.totalConnectionsCount === 0;
};

/**
 * Cancels all requests to the selected datasource
 */
export function cancelAllRequests(datasource: DatasourceSummaryStatusOutputV1): Promise<void> {
  const datasourceClass = datasource.datasourceClass;
  const datasourceId = datasource.datasourceId;
  return sqRequestsApi
    .cancelRequests({ datasourceClass, datasourceId })
    .then(() => {
      successToast({
        messageKey: 'ADMIN.DATASOURCE.CANCELED_ALL_SUCCESS',
      });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Request indexing of the first non DISABLED connection or reports the error given as parameter.
 */
export function requestIndex(datasource: DatasourceSummaryStatusOutputV1) {
  return sqAgentsApi
    .index(
      {
        syncMode: 'FULL',
      },
      {
        datasourceClass: datasource.datasourceClass,
        datasourceId: datasource.datasourceId,
      },
    )
    .then(() => {
      successToast({
        messageKey: 'ADMIN.DATASOURCES.REQUESTED_INDEX_SUCCESS',
        messageParams: {
          datasourceClass: datasource.datasourceClass,
          datasourceId: datasource.datasourceId,
        },
      });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function setDatasourceAllowRequests(
  datasource: DatasourceSummaryStatusOutputV1,
  allowRequests: boolean,
): Promise<void> {
  return sqItemsApi
    .setProperty({ value: allowRequests }, { id: datasource.id, propertyName: SeeqNames.Properties.Enabled })
    .then(() => {
      const newStatus = allowRequests
        ? 'ADMIN.DATASOURCES.ALLOWS_REQUESTS'
        : 'ADMIN.DATASOURCES.DOES_NOT_ALLOW_REQUESTS';
      const messageParams = {
        datasourceName: datasource.name,
        datasourceId: datasource.datasourceId,
      };
      successToast({ messageKey: newStatus, messageParams });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function setCacheEnabled(datasource: DatasourceSummaryStatusOutputV1, cacheEnabled: boolean): Promise<void> {
  const messageParams = {
    datasourceName: datasource.name,
  };
  if (cacheEnabled) {
    infoToast({
      messageKey: 'ADMIN.DATASOURCES.CACHE_ENABLED_INFO_MESSAGE',
      messageParams,
    });
  } else {
    infoToast({
      messageKey: 'ADMIN.DATASOURCES.CACHE_DISABLED_INFO_MESSAGE',
      messageParams,
    });
  }
  return sqItemsApi
    .setProperty({ value: cacheEnabled }, { id: datasource.id, propertyName: SeeqNames.Properties.CacheEnabled })
    .then(() => {})
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function setConnectionEnabled(connection: ConnectionStatusOutputV1, connectionEnabled: boolean): Promise<void> {
  const connectorName = connection.connectorName;
  const connectionKey = {
    agentName: connection.agentName,
    connectionName: connection.name,
    connectorName,
  };

  return sqAgentsApi
    .getConnection(connectionKey)
    .then(({ data }) =>
      sqAgentsApi.createOrUpdateConnection(
        {
          datasourceId: data.datasourceId,
          enabled: connectionEnabled,
          json: data.json,
          maxConcurrentRequests: data.maxConcurrentRequests,
          maxResultsPerRequests: data.maxResultsPerRequests,
          transforms: data.transforms,
        },
        connectionKey,
      ),
    )
    .then(({ data }) => {
      successToast({
        messageKey: data.enabled
          ? 'ADMIN.DATASOURCES.CONNECTION_HAS_BEEN_ENABLED'
          : 'ADMIN.DATASOURCES.CONNECTION_HAS_BEEN_DISABLED',
      });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function getConnection(connection) {
  const { agentName, connectorName, name: connectionName } = connection;
  return sqAgentsApi
    .getConnection({ agentName, connectorName, connectionName })
    .then(({ data }) => data)
    .then((data) => {
      const { backups, effectivePermissions, createdAt, updatedAt, datasourceId, ...connection } = data;
      if (_.isNil(connection.transforms)) {
        connection.transforms = undefined;
      } else {
        connection.transforms = JSON.parse(connection.transforms);
      }
      connection.json = JSON.parse(connection.json);
      return connection;
    });
}

export function getConnectionNames(agentName: string, connectorName: string) {
  return sqAgentsApi
    .getConnector({ agentName, connectorName })
    .then(({ data }) => data)
    .then((data: ConnectorOutputV1) => {
      let jsonObj;
      try {
        jsonObj = JSON.parse(data.json);
      } catch (e) {
        return [];
      }

      if (jsonObj.DatasourceManaged) {
        return Promise.reject({
          data: {
            statusMessage: i18next.t('ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTOR_IS_DATASOURCE_MANAGED'),
          },
        });
      }

      if (!_.isNil(jsonObj.Connections)) {
        return _.map(jsonObj.Connections, (connection) => {
          return { value: connection.Name, label: connection.Name };
        });
      }
      return [];
    })
    .catch(({ data }) => {
      return Promise.reject(_.get(data, 'statusMessage'));
    });
}

export function getConnectorNames(
  agentName: string,
  filter?: (c: { Name: string; Enabled: boolean }) => boolean,
): Promise<{ label: string; value: string }[]> {
  return sqAgentsApi
    .getAgent({ agentName })
    .then(({ data }) => data)
    .then((data: ConnectionOutputV1) => {
      let jsonObj;
      try {
        jsonObj = JSON.parse(data.json);
      } catch (e) {
        return [];
      }
      if (!_.isNil(jsonObj.Connectors)) {
        return _.map(_.filter(jsonObj.Connectors, filter), (connector) => {
          return { value: connector.Name, label: connector.Name };
        });
      }
      return [];
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
      return [];
    });
}

export function createOrUpdateConnection(
  isNew: boolean,
  agentName: string,
  datasourceId: string,
  connection: ConnectionOutputV1,
): Promise<void> {
  let transforms = null;
  if (_.isArray(connection.transforms)) {
    if (_.keys(connection.transforms).length > 0) {
      transforms = JSON.stringify(connection.transforms);
    } else {
      transforms = '[]';
    }
  }
  const body = {
    maxConcurrentRequests: connection.maxConcurrentRequests,
    maxResultsPerRequests: connection.maxResultsPerRequests,
    transforms,
    enabled: connection.enabled,
    json: JSON.stringify(connection.json),
  };
  if (isNew) {
    body['datasourceId'] = datasourceId;
  }
  const connectorName = connection.connectorName;
  const connectionName = connection.name;

  return sqAgentsApi
    .createOrUpdateConnection(body, {
      agentName,
      connectorName,
      connectionName,
    })
    .then(() => {
      if (isNew) {
        successToast({
          messageKey: 'ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTION_CREATED',
        });
      }
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
      return Promise.reject();
    });
}

/**
 * Sorts the provided list of connections in the correct order the frontend needs:
 * - First disconnected
 * - Then connecting
 * - Then indexing
 * - Then connected
 * - Then disabled
 */
export function sortConnections(connections: ConnectionStatusOutputV1[]): ConnectionStatusOutputV1[] {
  if (_.isNil(connections)) {
    return null;
  }

  let unprocessed = connections;

  const disconnected = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Disconnected),
    'name',
  );
  unprocessed = _.difference(unprocessed, disconnected);

  const connecting = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Connecting),
    'name',
  );
  unprocessed = _.difference(unprocessed, connecting);

  const indexing = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Indexing),
    'name',
  );
  unprocessed = _.difference(unprocessed, indexing);

  const connected = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Connected),
    'name',
  );
  unprocessed = _.difference(unprocessed, connected);

  const disabled = _.sortBy(
    _.filter(unprocessed, (c) => getConnectionStatus(c) === ConnectionStatus.Disabled),
    'name',
  );
  unprocessed = _.sortBy(_.difference(unprocessed, disabled), 'name');

  return _.concat(disconnected, connecting, indexing, connected, disabled, unprocessed);
}

export function getConnectionStatus(connection: ConnectionStatusOutputV1) {
  if (_.isNil(connection)) {
    return ConnectionStatus.Unknown;
  }

  if (connection.status === SeeqNames.Connectors.Connections.Status.Disconnected) {
    return ConnectionStatus.Disconnected;
  } else if (connection.status === SeeqNames.Connectors.Connections.Status.Disabled) {
    return ConnectionStatus.Disabled;
  } else if (connection.status === SeeqNames.Connectors.Connections.Status.Connecting) {
    return ConnectionStatus.Connecting;
  } else if (connection.status === SeeqNames.Connectors.Connections.Status.Connected) {
    if (connection.syncStatus === SyncStatusEnum.INPROGRESS) {
      return ConnectionStatus.Indexing;
    } else {
      return ConnectionStatus.Connected;
    }
  } else {
    return ConnectionStatus.Unknown;
  }
}

/**
 * Computes the url where the logs of the agent can be retrieved.
 */
export function computeLogUrl(
  agentName: string,
  agents: AgentStatusOutputV1[],
  connection?: ConnectionStatusOutputV1,
): string {
  const agent = _.find(agents, (a) => a.name === agentName);

  let logName;
  if (_.isNil(agent)) {
    logName = null;
  } else if (agent.remoteAgent) {
    // Keep this in sync with RemoteAgentLoggingService
    logName = agent.name
      .replace(/[\\/:*?\"<>|]/g, '')
      .replace(/[.\s]/g, '_')
      .replace(/(\d)$/g, '$1_');
  } else if (_.includes(agent.name, 'JVM Agent')) {
    logName = 'jvm-link';
  } else if (_.includes(agent.name, '.NET Agent')) {
    logName = 'net-link';
  } else {
    logName = null;
  }

  let url = '/logs';

  if (!_.isNil(logName)) {
    url += `?log=${encodeURIComponent(logName)}`;
  }

  if (!_.isNil(connection)) {
    if (_.isNil(logName)) {
      url += '?';
    } else {
      url += '&';
    }

    url += `threadContains=${encodeURIComponent(connection.connectionId)}`;
  }

  return url;
}

/**
 * Fetch parameters required by the ManageDatasourceModal in the format it expects
 *
 * @param id - the datasource ID
 */
export function fetchManageDatasourceParams(id: string): Promise<ManageDatasourceParams | void> {
  return sqDatasourcesApi
    .getDatasource({ id })
    .then(({ data: { name, indexingScheduleSupported, additionalProperties } }) => {
      const indexingFrequency = _.chain(additionalProperties)
        .filter(['name', SeeqNames.Properties.IndexingFrequency])
        .map((prop) => ({ value: prop.value, units: prop.unitOfMeasure }))
        .first()
        .value();
      const nextScheduledIndexAt = _.chain(additionalProperties)
        .filter(['name', SeeqNames.Properties.NextScheduledIndexAt])
        .map((prop) => moment.utc(prop.value / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND).toISOString())
        .first()
        .value();

      return {
        id,
        name,
        indexingScheduleSupported,
        indexingFrequency,
        nextScheduledIndexAt,
      };
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Update the datasource
 *
 * @param id - the datasource ID
 * @param name - the new name of the datasource
 * @param indexingFrequency - the new indexing frequency
 * @param nextScheduledIndexAt - the new next scheduled indexing time, formatted as ISO 8601 in UTC
 * @param connections - the list of connections of the datasource
 * @param connectionsEnabled - a boolean representing the new global enabled state of the connections
 * @param connectionsEnabledWasUpdated - a boolean representing whether the connections' enabled status should be
 * updated
 */
export function updateDatasource({
  id,
  name,
  indexingFrequency,
  nextScheduledIndexAt,
  connections,
  connectionsEnabled,
  connectionsEnabledWasUpdated,
}: UpdateDatasourceParams) {
  const properties: ScalarPropertyV1[] = [
    {
      name: SeeqNames.Properties.Name,
      value: name,
    },
  ];

  if (!_.isUndefined(nextScheduledIndexAt)) {
    properties.push({
      name: SeeqNames.Properties.NextScheduledIndexAt,
      value: moment.utc(nextScheduledIndexAt).valueOf() * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
      unitOfMeasure: 'ns',
    });
  }

  if (!_.isUndefined(indexingFrequency)) {
    properties.push({
      name: SeeqNames.Properties.IndexingFrequency,
      value: indexingFrequency.value,
      unitOfMeasure: indexingFrequency.units,
    });
  }

  if (connectionsEnabledWasUpdated) {
    return Promise.all([
      ..._.map(connections, (connection) => setConnectionEnabled(connection, connectionsEnabled)),
      sqItemsApi.setProperties(properties, { id }),
    ]);
  } else {
    return sqItemsApi.setProperties(properties, { id });
  }
}

export function getTrackableMessage(datasourcesStatus: DatasourcesStatusOutputV1) {
  if (_.isNil(datasourcesStatus)) {
    return datasourcesStatus;
  }
  const agents = _.isNil(datasourcesStatus.agents)
    ? datasourcesStatus.agents
    : _.map(datasourcesStatus.agents, (agent) => _.pick(agent, ['name', 'status']));

  const datasources = _.isNil(datasourcesStatus.datasources)
    ? datasourcesStatus.datasources
    : _.map(datasourcesStatus.datasources, (datasource) => {
        const relevantFields = _.pick(datasource, ['id', 'name', 'datasourceClass', 'datasourceId', 'syncProgress']);
        relevantFields.syncProgress = _.pick(relevantFields.syncProgress, [
          'signalCount',
          'conditionCount',
          'scalarCount',
          'assetCount',
          'userGroupCount',
        ]);
        return relevantFields;
      });

  return { agents, datasources };
}

export function getDatasourceItemsCount(datasource: DatasourceSummaryStatusOutputV1) {
  const syncProgress = datasource.syncProgress;

  return _.isNil(syncProgress)
    ? 0
    : (syncProgress.signalCount || 0) +
        (syncProgress.conditionCount || 0) +
        (syncProgress.scalarCount || 0) +
        (syncProgress.assetCount || 0) +
        (syncProgress.userGroupCount || 0);
}

export function getPreviousDatasourceItemsCount(datasource: DatasourceSummaryStatusOutputV1) {
  const syncProgress = datasource.syncProgress;

  return _.isNil(syncProgress)
    ? 0
    : (syncProgress.previousSignalCount || 0) +
        (syncProgress.previousConditionCount || 0) +
        (syncProgress.previousScalarCount || 0) +
        (syncProgress.previousAssetCount || 0) +
        (syncProgress.previousUserGroupCount || 0);
}

/**
 * Converts a nanoseconds duration to seconds (rounded).
 *
 * @param nanoseconds - the duration to be converted
 * @return the duration expressed in seconds
 */
export function convertNanosecondsToSeconds(nanoseconds: number) {
  return Math.round(nanoseconds / 1000000000);
}

export interface UpdateDatasourceParams {
  id: string;
  name: string;
  indexingFrequency: { value: number; units: string };
  nextScheduledIndexAt: string; // ISO 8601 in UTC (e.g. '2021-07-10T00:24:00.000Z')
  connections?: ConnectionStatusOutputV1[];
  connectionsEnabled?: boolean;
  connectionsEnabledWasUpdated?: boolean;
}

export interface ManageDatasourceParams extends UpdateDatasourceParams {
  indexingScheduleSupported: boolean;
}

export interface FilterParameters {
  name: string;
  datasourceClass: string;
  datasourceId: string;
  agentName: string;
  status: string;
}

export enum DatasourceStatus {
  Unknown = 'Unknown',
  Error = 'Error',
  Indexing = 'Indexing',
  Warning = 'Warning',
  Happy = 'Happy',
  NotConnectable = 'NotConnectable',
}

export enum ConnectionStatus {
  Unknown = 'Unknown',
  Disconnected = 'Disconnected',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Indexing = 'Indexing',
  Disabled = 'Disabled',
}
